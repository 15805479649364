import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router";
import 'react-library/src/App.css'
import { Home } from './PAGES/Home';
import { Login } from './PAGES/Login';
import { Signup } from './PAGES/Signup';
import { Campaigns } from './PAGES/Campaigns';
import { Create } from './PAGES/Create';
import { Notifications } from './PAGES/Notifications';
import { BuyViews } from './PAGES/BuyViews';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/campaigns" element={<Campaigns />} />
      <Route path="/create" element={<Create />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/buy-views" element={<BuyViews />} />
    </Routes>
  </BrowserRouter>
);

