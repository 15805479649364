// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
    apiKey: "AIzaSyAtOFoN04wOZh1d6IdS61JXdpVVrjIvluY",
    authDomain: "ads-mayhem.firebaseapp.com",
    projectId: "ads-mayhem",
    storageBucket: "ads-mayhem.firebasestorage.app",
    messagingSenderId: "668047015403",
    appId: "1:668047015403:web:7dc9700241dc8983c4edae",
    measurementId: "G-FQ0JQG1R82"
};

export const iicFirebaseConfig = {
    apiKey: "AIzaSyCIFGVe8rYbtty5qnkYFIg-0OUJhnU0LpI",
    authDomain: "iic-development.firebaseapp.com",
    projectId: "iic-development",
    storageBucket: "iic-development.appspot.com",
    messagingSenderId: "264501829712",
    appId: "1:264501829712:web:dba679ac95f127ecfb7155",
    measurementId: "G-7X4LT5GKJB"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const app2 = initializeApp(iicFirebaseConfig, "secondary")
export const db = getFirestore(app);
export const db2 = getFirestore(app2)
export const auth = getAuth(app);
export const storage = getStorage(app);




