import { useNavigate } from "react-router";
import { Clickable } from "../COMPONENTS/Clickable";
import { Navigation } from "../UTILITIES/Navigation";
import { emailFix } from 'react-library/src/FUNCTIONS/misc.js'
import { useEffect, useState } from "react";
import { Loading } from '../COMPONENTS/Loading'
import { auth_CheckUser, auth_SignIn } from 'react-library/src/firebase.js'
import { auth, db } from '../firebase'
import { Footer } from "../UTILITIES/Footer";



export function Login() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    async function onLogin() {
        const email = document.querySelector('#tbEmail').value;
        const password = document.querySelector('#tbPassword').value;

        if (email == "" || password == "") {
            alert("Please enter a valid email and password.");
            return;
        }

        setLoading(true)
        console.log(password)
        await auth_SignIn(auth, email, password, (user) => {
            if (user !== undefined && user !== null) {
                console.log('SUCCESS');
                navigate('/campaigns')
                setLoading(false)
            } else {
                alert("Please try again with a valid email and password.")
                setLoading(false)
            }
        })


    }

    function onLoad() {
        auth_CheckUser(auth, (user) => {
            if (user !== null) {
                navigate('/campaigns')
            }
        })
        document.addEventListener('keydown', function (event) {
            if (event.key === 'Enter') {
                onLogin()
            }
        });
    }

    useEffect(() => {
        onLoad()
    }, [])

    return <div className="poppins">
        {loading && <Loading />}
        <Navigation />
        <div className="main separate-v">
            <div></div>
            <div className="separate-h">
                <div></div>
                <div className="full-width gap" style={{ maxWidth: 300 }}>
                    <h1 className="text-xl">Login</h1>
                    <div className="border-b-1-black">
                        <input id="tbEmail" type="text" className="full-width p-v no-border no-outline" placeholder="Email" />
                    </div>
                    <br />
                    <div className="border-b-1-black">
                        <input id="tbPassword" type="password" className="full-width p-v no-border no-outline" placeholder="Password" />
                    </div>
                    <br />
                    <div className="separate-h">
                        <div></div>
                        <Clickable onPressed={() => { onLogin() }}>
                            <div className="bg-red full-radius p-sm text-center fit-width">
                                <p className="white m-h">login</p>
                            </div>
                        </Clickable>
                    </div>
                    <br />
                    <Clickable onPressed={() => {
                        // 
                    }}>
                        <p className="underline text-center">forgot password?</p>
                    </Clickable>
                </div>
                <div></div>
            </div>
            <div></div>
        </div>

        <Footer />
    </div>
}