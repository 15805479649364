import logo from '../IMAGES/logo.png'
import { Clickable } from '../COMPONENTS/Clickable'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react';
import { GrMenu } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineArrowOutward } from "react-icons/md";
import { LuLogOut } from "react-icons/lu";
import { auth_SignOut } from 'react-library/src/firebase';
import { auth } from '../firebase';
// 

export function DashNavigation() {
    const navigate = useNavigate();
    const [showNav, setShowNav] = useState(false);

    async function onSignOut() {
        auth_SignOut(auth, (success) => {
            if (success) {
                navigate('/login')
            }
        })
    }


    return <div className="poppins">
        <div className='separate-h'>
            <Clickable onPressed={() => {
                navigate('/campaigns')
            }}>
                <img src={logo} style={{ width: 80, height: 80 }} className='radius-br-sm' />
            </Clickable>
            {/*  */}
            <div className='p'>
                <Clickable onPressed={() => {
                    setShowNav(true)
                }}>
                    <GrMenu size={25} />
                </Clickable>
            </div>
        </div>
        {
            showNav &&
            <div className='fixed top right bg-white full-width border-l-1-black' style={{ maxWidth: 400, zIndex: 100 }}>
                <div className='separate-h'>
                    <div></div>
                    <div className='p'>
                        <Clickable onPressed={() => {
                            setShowNav(false)
                        }}>
                            <AiOutlineClose size={25} />
                        </Clickable>
                    </div>
                </div>
                <Clickable onPressed={() => {
                    navigate('/campaigns')
                }}>
                    <div className='p border-b-1-black'>
                        <div className='separate-h vertical-center'>
                            <p className='thin text-md'>Campaigns</p>
                            <MdOutlineArrowOutward size={25} />
                        </div>
                    </div>
                </Clickable>
                <Clickable onPressed={() => {
                    navigate('/notifications')
                }}>
                    <div className='p border-b-1-black'>
                        <div className='separate-h vertical-center'>
                            <p className='thin text-md'>Notifications</p>
                            <MdOutlineArrowOutward size={25} />
                        </div>
                    </div>
                </Clickable>
                <Clickable onPressed={() => {
                    navigate('/buy-views')
                }}>
                    <div className='p border-b-1-black bg-purple'>
                        <div className='separate-h vertical-center'>
                            <p className='thin text-md white'>Buy Views</p>
                            <MdOutlineArrowOutward size={25} className='white' />
                        </div>
                    </div>
                </Clickable>
                <Clickable onPressed={() => {
                    navigate('/create')
                }}>
                    <div className='p border-b-1-black bg-red'>
                        <div className='separate-h vertical-center'>
                            <p className='thin text-md white'>Create Campaign</p>
                            <MdOutlineArrowOutward size={25} className='white' />
                        </div>
                    </div>
                </Clickable>
                <div className='p border-b-1-black'>
                    <Clickable onPressed={() => {
                        onSignOut()
                    }}>
                        <div className='separate-h'>
                            <p className='red'>Sign Out</p>
                            <LuLogOut size={20} color='red' />
                        </div>
                    </Clickable>
                </div>
            </div>
        }

    </div>
}