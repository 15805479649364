export function Footer() {
    return <div className="poppins bg-black">
        <div className="row p white box">
            <div className="lg2 xl3">
                <p>Created by <span onClick={() => {
                    window.open('https://innovativeinternetcreations.com/apps')
                }} className="space underline pointer">Innovative Internet Creations</span>.</p>
                <p className="text-xs space">a nothing bagel ver. 2.0</p>
            </div>
            <div className="sm0"></div>
            <div className="sm0"></div>
            <div className="xl1">
                <p className="text-right text-xs">copyright 2024.</p>
            </div>
        </div>
    </div>
}