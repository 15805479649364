import { auth_CheckUser, firebase_GetDocument } from "react-library/src/firebase";
import { DashNavigation } from "../UTILITIES/DashNavigation";
import { auth, db } from "../firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { server_POST } from 'react-library/src/SERVER/server.js'
import phone1 from '../IMAGES/home2.png'
import { Clickable } from "../COMPONENTS/Clickable";
import { Loading } from "../COMPONENTS/Loading";
import { Footer } from "../UTILITIES/Footer";

export function BuyViews() {
    const [me, setMe] = useState(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const viewNums = [
        100, 250, 500,
    ]
    const pricePerView = 0

    async function onBuyViews() {

        const viewsNum = parseInt(document.querySelector('#ddViews').value);
        if (me.views + viewsNum > 2000) {
            alert("At the moment, you cannot have more than 2000 views at a time. Sorry!")
            return
        }

        setLoading(true)
        const body = {
            email: me.email,
            amount: viewsNum * pricePerView * 100,
            currency: 'USD',
            userId: me.id,
            views: viewsNum,
            customerId: me.customerId !== undefined ? me.customerId : ""
        }
        // 

        const res = await server_POST('handle-payment-link', body);
        setLoading(false)
        window.open(res.url, '_self');
    }

    async function onLoad() {
        auth_CheckUser(auth, (user) => {
            if (user !== null) {
                firebase_GetDocument(db, 'Businesses', user.uid, (doc) => {
                    setMe(doc)
                })
            } else {
                navigate('/login')
            }
        })
    }

    useEffect(() => {
        onLoad()
    }, [])

    return <div className="poppins">
        {loading && <Loading />}
        <DashNavigation />
        <div className="main">

            <div className="bg-black">
                <p className="white text-md text-normal">Talk about the views..</p>
            </div>
            <br />
            <div className="row box p border-b-1-black gap">
                <div className="flex-column box lg2">
                    <div className="">
                        <p className="thin text-md">To make it easy for your business, we set up a system where you can purchase views at an affordable price and use them in any campaign you want.</p>
                    </div>
                    <br />
                    <div className="gap">
                        <p className="text-md">for example...</p>
                        <p className="thin blue">You’re launching a new product and want to promote three exclusive discount campaigns: 20%, 35%, and 45% off. With a budget to purchase 1,500 views, you can allocate 500 views to each campaign for maximum exposure.</p>
                    </div>
                    <br />
                    <div className="">
                        <p className='thin'>Any views that are not used when the campaign is expired or deactivated, will be refunded back to your total.</p>
                    </div>
                    <br />
                    <p>Views</p>
                    <select id="ddViews" className="full-width p no-outline">
                        {
                            viewNums.map((view, i) => {
                                return <option key={i} value={view}>
                                    {view} views
                                </option>
                            })
                        }
                    </select>
                    <br />
                    <div className="fit-width p">
                        <Clickable onPressed={() => {
                            onBuyViews()
                        }}>
                            <div className="full-radius bg-purple p-sm">
                                <p className="white m-h">purchase views</p>
                            </div>
                        </Clickable>
                    </div>

                </div>
                <div className="lg2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={phone1} style={{ width: '100%', height: '100%', maxWidth: 400 }} />
                </div>
            </div>

        </div>
        <Footer />
    </div>
}