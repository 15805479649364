import { Navigation } from "../UTILITIES/Navigation";
import doodle from '../IMAGES/doodle.jpg'
import { FaApple } from "react-icons/fa";
import { Clickable } from "../COMPONENTS/Clickable";
import { FaGooglePlay } from "react-icons/fa";
import home1 from '../IMAGES/home1.png'
import home2 from '../IMAGES/home2.png'
import { Footer } from "../UTILITIES/Footer";
import { useEffect } from "react";
import { moveStuff } from "../migration";

export function Home() {
    async function onLoad() {
        // await moveStuff('KoukokuAds', 'Views')
    }

    useEffect(() => {
        onLoad()
    }, [])

    return <div className="poppins">
        <Navigation />
        <div className="main relative">

            <div className="row p box gap">
                <div className="lg2 flex-column">
                    <div>
                        <h1 className="text-xxl line-sm">coupons.<br />ads.<br />deals.<br /><span className="red">no bots.</span><br /><span className="red">real people.</span></h1>
                    </div>
                    <br />
                    <div>
                        <p>We specialize in connecting businesses with new customers, bringing communities closer together. Every deal highlights the incredible things happening right around you. Keeping it local, keeping it simple—because great experiences start close to home.</p>
                    </div>
                </div>
                <div className="lg1 xl2">
                    <img src={doodle} style={{ width: '100%' }} />
                </div>
            </div>

            <div className="row">

                {/* USER */}
                <div className="xl2">
                    <div className="flex-column p box gap">
                        <h2 className="text-lg normal white bg-black fit-height lg2">User App</h2>

                        <p className="xl2">A straightforward app that lets you explore posts showcasing the best deals near any location you choose—whether it’s around your current spot or where you’re traveling. Filter by category, save coupons for later, and follow your favorite businesses to stay in the loop on their latest offers.</p>
                    </div>
                    <div className="">
                        <div className="flex-row space-around">
                            <img src={home1} style={{ width: "100%", maxWidth: 300, height: '100%' }} />
                        </div>
                        <div className="flex-row space-around vertical-center">
                            <div className="flex-column gap">
                                <div className="p-sm bg-black fit-width full-radius">
                                    <Clickable onPressed={() => {
                                        window.open('https://apps.apple.com/us/app/ads-mayhem/id6737462117', '_blank')
                                    }}>
                                        <div className="flex-row vertical-center gap-sm m-h">
                                            <FaApple color="white" size={20} /><p className="white">App Store</p>
                                        </div>
                                    </Clickable>
                                </div>

                                <div className="p-sm bg-light-blue fit-width full-radius">
                                    <Clickable onPressed={() => {
                                        window.open('https://play.google.com/store/apps/details?id=com.iicdev.AdsMahem&hl=en_US', '_blank')
                                    }}>
                                        <div className="flex-row vertical-center gap-sm m-h">
                                            <FaGooglePlay color="white" size={20} /><p className="white">Google Play</p>
                                        </div>
                                    </Clickable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* BUSINESS */}
                <div className="xl2">
                    <div className="flex-column p box gap">
                        <h2 className="text-lg normal white bg-black fit-height lg2">Business App</h2>

                        <p className="xl2">As a business, tracking invaluable analytics from user interactions is essential. From views and clicks to coupon scans, these insights provide a clear picture of your performance. Understanding where your traction is coming from is the key to scaling your business and maximizing growth.</p>
                    </div>
                    <div className="">
                        <div className="flex-row space-around">
                            <img src={home2} style={{ width: "100%", maxWidth: 300, height: '100%' }} />
                        </div>
                        <div className="flex-row space-around vertical-center">
                            <div className="flex-column gap">
                                <div className="p-sm bg-black fit-width full-radius">
                                    <Clickable onPressed={() => {
                                        window.open('https://apps.apple.com/us/app/ads-mayhem-business/id6737673889', '_blank')
                                    }}>
                                        <div className="flex-row vertical-center gap-sm m-h">
                                            <FaApple color="white" size={20} /><p className="white">App Store</p>
                                        </div>
                                    </Clickable>
                                </div>

                                <div className="p-sm bg-light-blue fit-width full-radius">
                                    <Clickable onPressed={() => {
                                        window.open('https://play.google.com/store/apps/details?id=com.iicdev.AdsMahemBusiness', '_blank')
                                    }}>
                                        <div className="flex-row vertical-center gap-sm m-h">
                                            <FaGooglePlay color="white" size={20} /><p className="white">Google Play</p>
                                        </div>
                                    </Clickable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
        </div>
        <Footer />
    </div>
}