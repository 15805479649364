import logo from '../IMAGES/logo.png'
import { Clickable } from '../COMPONENTS/Clickable'
import { useNavigate } from 'react-router'
import { useState } from 'react';
import { GrMenu } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineArrowOutward } from "react-icons/md";

export function Navigation() {
    const navigate = useNavigate();
    const [showNav, setShowNav] = useState(false);

    return <div className="poppins">
        <div className='separate-h'>
            <Clickable onPressed={() => {
                navigate('/')
            }}>
                <img src={logo} style={{ width: 80, height: 80 }} className='radius-br-sm' />
            </Clickable>
            {/*  */}
            <div className='p'>
                <Clickable onPressed={() => {
                    setShowNav(true)
                }}>
                    <GrMenu size={25} />
                </Clickable>
            </div>
        </div>
        {
            showNav &&
            <div className='fixed top right bg-white full-width border-l-1-black' style={{ maxWidth: 400, zIndex: 100 }}>
                <div className='separate-h'>
                    <div></div>
                    <div className='p'>
                        <Clickable onPressed={() => {
                            setShowNav(false)
                        }}>
                            <AiOutlineClose size={25} />
                        </Clickable>
                    </div>
                </div>
                <Clickable onPressed={() => {
                    navigate('/')
                }}>
                    <div className='p border-b-1-black'>
                        <div className='separate-h vertical-center'>
                            <p className='thin text-md'>Home</p>
                            <MdOutlineArrowOutward size={25} />
                        </div>
                    </div>
                </Clickable>
                <Clickable onPressed={() => {
                    navigate('/login')
                }}>
                    <div className='p border-b-1-black'>
                        <div className='separate-h vertical-center'>
                            <p className='thin text-md'>Login</p>
                            <MdOutlineArrowOutward size={25} />
                        </div>
                    </div>
                </Clickable>
                <Clickable onPressed={() => {
                    navigate('/signup')
                }}>
                    <div className='p border-b-1-black'>
                        <div className='separate-h vertical-center'>
                            <p className='thin text-md red'>Sign Up</p>
                            <MdOutlineArrowOutward size={25} className='red' />
                        </div>
                    </div>
                </Clickable>
            </div>
        }

    </div>
}