import { useEffect, useRef, useState } from "react";
import { Clickable } from "../COMPONENTS/Clickable";
import { DashNavigation } from "../UTILITIES/DashNavigation";
import { RiImageAddFill } from "react-icons/ri";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { Loading } from "../COMPONENTS/Loading";
import ngeohash from 'ngeohash';
import { auth_CheckUser, firebase_CreateDocument, firebase_GetAllDocumentsQueried, firebase_GetDocument, firebase_UpdateDocument, storage_UploadMedia } from "react-library/src/firebase";
import { auth, db, storage } from "../firebase";
import { useNavigate } from "react-router";
import { randomString } from "react-library/src/FUNCTIONS/misc";
import { Footer } from "../UTILITIES/Footer";

export function Create() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [me, setMe] = useState(null)
    const [image, setImage] = useState(null)
    const [isNotQR, setIsNotQR] = useState(false);
    const viewNums = [
        100, 200, 300, 400, 500
    ]

    // MAP STUFF

    const [address, setAddress] = useState('');
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const autocompleteRef = useRef(null);  // Reference for the address input field

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCIFGVe8rYbtty5qnkYFIg-0OUJhnU0LpI", // replace with your API key
        libraries: ['places'], // load places library for autocomplete
    });

    const mapContainerStyle = {
        width: '100%',
        height: '400px',
        borderRadius: '10px',
        marginTop: '10px'
    };

    const center = {
        lat: lat || 37.7749, // default latitude (San Francisco)
        lng: lng || -122.4194, // default longitude (San Francisco)
    };

    // 


    function onSelectImage(event) {
        const file = event.target.files[0];

        if (!file) {
            alert("No file selected. Please select an image.");
            return;
        }

        // Validate file type (image only)
        const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
        if (!validImageTypes.includes(file.type)) {
            alert("Invalid file type. Please upload an image (JPEG, PNG, GIF, WEBP).");
            return;
        }

        // Example: Create a preview URL
        const reader = new FileReader();
        reader.onload = (e) => {
            console.log("Image preview URL:", e.target.result);
            // You can display the preview or use it as needed
            setImage(e.target.result)
        };
        reader.readAsDataURL(file);
    }

    async function onSubmitRequest() {
        if (image == null) {
            alert("Please provide an image for this campaign.")
            return;
        }

        const viewsNum = parseInt(document.querySelector('#ddViews').value.split(' ')[0]);

        if (viewsNum > me.views) {
            alert("You do not have enough views for this campaign. Please purchase more from your dashboard.")
            return;
        }

        const details = document.querySelector('#tbDetails').value.replaceAll('\n', 'jjj');
        const link = document.querySelector('#tbLink').value;
        const autoRefill = document.querySelector('#cbRefill').checked;
        const hidePhone = document.querySelector('#cbHidePhone').checked;
        const hideLocation = document.querySelector('#cbHideLocation').checked;
        const isNotQR = document.querySelector('#cbDisable').checked;
        var expDate = 0;
        var isRepeating = false;
        if (!isNotQR) {
            expDate = document.querySelector('#ddExpDate').value;
            if (expDate !== undefined && expDate !== null) {
                expDate = new Date(`${expDate}T00:00:00Z`);
                expDate = expDate.getTime()
            } else {
                alert("Please select a valid expiration date.")
                return
            }

            // 
            isRepeating = document.querySelector('#cbRepeated').checked;
        }
        const address = document.querySelector('#tbAddress').value;

        if (address == "") {
            alert("Please provide a valid address.")
            return
        }

        const geohash = ngeohash.encode(lat, lng, 10)

        if (details == "") {
            alert("Provide additional details to give customers more context or general information about this post.")
            return;
        }

        const imagePath = `Images/${randomString(12)}.jpg`
        const args = {
            active: false,
            address: address,
            category: me.category,
            date: new Date().getTime(),
            details: details.replaceAll('\n', 'jjj'),
            expDate: expDate,
            geohash: geohash,
            imagePath: imagePath,
            isNotQR: isNotQR,
            isRepeating: isRepeating,
            isRefill: autoRefill,
            isHidePhone: hidePhone,
            isHideLocation: hideLocation,
            link: link,
            seenViews: 0,
            userId: me.id,
            views: viewsNum
        }

        setLoading(true)

        await storage_UploadMedia(storage, image, imagePath, async (success) => {
            if (success) {
                await firebase_CreateDocument(db, 'Requests', randomString(25), args, async (succ2) => {
                    if (succ2) {
                        await firebase_UpdateDocument(db, 'Businesses', me.id, {
                            views: me.views - viewsNum
                        }, (succ3) => {
                            console.log('UPDATED')
                        })
                        await firebase_CreateDocument(db, 'Notifications', randomString(25), {
                            title: "Ad Request Created",
                            body: "Your ad has been submitted to the Ads Mayhem team. Please wait while we approve your ad. Check back in a few hours or the next day for the status of your ad.",
                            date: new Date().getTime(),
                            userId: me.id,
                        }, (succ3) => {
                            if (succ3) {
                                navigate('/notifications')
                                setLoading(false);
                            }
                        })

                    } else {
                        alert('Something went wrong. Please try again later.');
                        navigate('/campaigns')
                        setLoading(false);
                        return
                    }
                })
            } else {
                alert("Something went wrong.")
                setLoading(false);
                return
            }
        })
    }

    async function onLoad() {
        await auth_CheckUser(auth, async (user) => {
            if (user !== null) {
                await firebase_GetAllDocumentsQueried(db, 'Campaigns', [
                    { field: 'userId', operator: '==', value: user.uid },
                    { field: 'active', operator: '==', value: true }
                ], (docs) => {
                    if (docs.length == 8) {
                        navigate("/campaigns")
                        alert("You cannot have more than 8 active campaigns. Sorry!")
                        return
                    }
                })
                firebase_GetDocument(db, 'Businesses', user.uid, (doc) => {
                    setMe(doc)
                    const id = doc.id

                })
            } else {
                navigate('/')
            }
        })
    }

    useEffect(() => {
        onLoad()

        if (isLoaded && autocompleteRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current);
            autocomplete.setFields(['geometry', 'formatted_address']); // Specify the required data

            // Listener for when a place is selected
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    const location = place.geometry.location;
                    setLat(location.lat());
                    setLng(location.lng());
                    setAddress(place.formatted_address);
                }
            });
        }
    }, [isLoaded]);

    if (!isLoaded) {
        return <Loading />;
    }

    return (
        <div className="poppins">
            {loading && <Loading />}
            <DashNavigation />
            <div className="main">
                <div className="row gap box">
                    <div className="border-1-black box">
                        <div className="bg-black">
                            <p className="white thin text-md">Upload Image</p>
                        </div>
                        <div className="p">
                            <p className="thin text-xs">
                                Choose an image showcasing your coupon, ad, deal, or discount.
                                Keep it simple—include only the essentials. Save longer details and
                                links for the section below.
                            </p>
                            <br />
                            <div className="flex-row space-around">
                                <div>
                                    {
                                        image == null ?
                                            <Clickable
                                                onPressed={() => {
                                                    document.querySelector("#fuImage").click();
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: 250,
                                                        height: 250,
                                                        display: "flex", // Enables Flexbox
                                                        justifyContent: "center", // Centers horizontally
                                                        alignItems: "center", // Centers vertically
                                                    }}
                                                    className="bg-black flex-column"
                                                >
                                                    <div>
                                                        <RiImageAddFill size={40} color="white" />
                                                    </div>
                                                </div>
                                            </Clickable> :
                                            <Clickable
                                                onPressed={() => {
                                                    document.querySelector("#fuImage").click();
                                                }}
                                            >
                                                <div className="border-1-black">
                                                    <img src={image} style={{ width: 250, height: 250 }} className="fill" />
                                                </div>
                                            </Clickable>
                                    }
                                    {/*  */}
                                    <p className="p-sm text-xs text-center">Tap to change image</p>
                                </div>
                            </div>
                            <input
                                type="file"
                                id="fuImage"
                                style={{ display: "none" }}
                                accept="image/*" // Restrict file picker to images only
                                onChange={onSelectImage} // Attach the handler
                            />

                        </div>
                    </div>
                    {/*  */}
                    <div className="border-1-black box">
                        <div className="bg-black">
                            <p className="white thin text-md">Views</p>
                        </div>
                        <div className="p">
                            <p className="thin text-xs">Select the number of views for your post. It will deactivate automatically once this limit is reached.</p>
                            <br />
                            <select id="ddViews" className="p no-outline">
                                {
                                    viewNums.map((view, i) => {
                                        return <option key={i}>{`${view.toString()} views`}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    {/*  */}
                    <div className="border-1-black box">
                        <div className="bg-black">
                            <p className="white thin text-md">Post Details</p>
                        </div>
                        <div className="p">
                            <p className="text-xs thin">Provide additional details for your campaign to display below the image for potential customers.</p>
                            <br />
                            <p>details</p>
                            <div className="border-b-1-black">
                                <textarea maxLength={400} id="tbDetails" placeholder="Type details here.." className="no-outline no-border full-width poppins" style={{ height: 120 }} ></textarea>
                            </div>
                            <br />
                            <p>link</p>
                            <div className="border-b-1-black">
                                <input type="text" id="tbLink" placeholder="ex. https://adsmayhem.com/contact" className="no-outline no-border p-v full-width" />
                            </div>
                            <div className="text-right p-v">
                                <Clickable onPressed={() => {
                                    const link = document.querySelector('#tbLink').value;
                                    if (link !== "") {
                                        window.open(link, '_blank')
                                    }
                                }}><p className="underline thin">test link</p></Clickable>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className="border-1-black box">
                        <div className="bg-black">
                            <p className="white thin text-md">Settings</p>
                        </div>
                        <div className="p">
                            <p className="thin text-xs">Choose your post settings to define how your campaign will function.</p>
                            <br />

                            <div className="flex-row gap">
                                <input type="checkbox" className="p-sm" id="cbRefill" />
                                <p className="">auto-refill views</p>
                            </div>
                            <p className="text-xs">reactivate this campaign automatically after it has used all its views.</p>
                            <br />

                            <div className="flex-row gap">
                                <input type="checkbox" className="p-sm" id="cbHidePhone" />
                                <p className="">hide calling</p>
                            </div>
                            <p className="text-xs">checking this will not allow customers to call you through this campaign.</p>
                            <br />

                            <div className="flex-row gap">
                                <input type="checkbox" className="p-sm" id="cbHideLocation" />
                                <p className="">hide location</p>
                            </div>
                            <p className="text-xs">your location will only be used to appear on their feed, but not to get directions.</p>
                            <br />

                            <div className="flex-row gap">
                                <input type="checkbox" className="p-sm" id="cbDisable" onChange={(e) => {
                                    setIsNotQR(e.target.checked)
                                }} />
                                <p className="">disable qr code</p>
                            </div>
                            <p className="text-xs">disabling the qr code setting will remove the ability for customers to view a QR code on this post.</p>

                            {
                                !isNotQR && <div>
                                    <br />
                                    <p className="">expiration date</p>
                                    <input type="date" className="p-sm" id="ddExpDate" />
                                    <p className="text-xs">once the current date reaches this expiration date, the post campaign will be deactivated.</p>

                                    <br />
                                    <div className="flex-row gap">
                                        <input type="checkbox" className="p-sm" id="cbRepeated" />
                                        <p className="">allow repeated scan</p>
                                    </div>
                                    <p className="text-xs">enable multiple scans of the QR code by the customer, rather than limiting it to just one scan by default.</p>
                                </div>
                            }


                        </div>
                    </div>
                    {/*  */}
                    <div className="border-1-black box lg2 xl4">
                        <div className="bg-black">
                            <p className="white thin text-md">Location</p>
                        </div>
                        <div className="p">
                            <p className="text-xs thin">Enter the location for this post, such as a restaurant, studio, headquarters, etc.</p>
                            <br />
                            <div>
                                <p className="">address</p>
                                <div className="border-b-1-black">
                                    <input
                                        ref={autocompleteRef}  // Reference to the autocomplete input field
                                        id="tbAddress"
                                        type="text"
                                        className="full-width no-border no-outline p-v"
                                        value={address}
                                        placeholder="Search for your address"
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* Map Section */}
                            <div className='create-map'>
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={17}
                                    center={center}
                                >
                                    {lat && lng && <Marker position={{ lat, lng }} />}
                                </GoogleMap>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ------------------------------ */}
                <br />
                <br />
                <div className="border-1-black">
                    <div className="bg-black">
                        <p className="white thin text-md">Send Request</p>
                    </div>
                    <div className="p">
                        <p className="thin text-xs">
                            Before you send this request. Make sure you filled out all information to the best of your ability.
                        </p>
                        <br />
                        <div className="flex-row space-around">
                            <Clickable onPressed={() => {
                                onSubmitRequest()
                            }}>
                                <div className="full-radius p m-h bg-red">
                                    <p className="white">Send Request</p>
                                </div>
                            </Clickable>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
            <Footer />
        </div>
    );
}