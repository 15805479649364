import { useEffect, useState } from "react";
import { Clickable } from "../COMPONENTS/Clickable";
import { Navigation } from "../UTILITIES/Navigation";
import { auth_CreateUser, firebase_CreateDocument, firebase_GetAllDocuments } from "react-library/src/firebase.js";
import { emailFix } from 'react-library/src/FUNCTIONS/misc.js'
import { useNavigate } from "react-router";
import { Loading } from '../COMPONENTS/Loading'
import { auth, db } from "../firebase";
import { sortArrayByObj } from 'react-library/src/FUNCTIONS/arrays.js'
import { Footer } from "../UTILITIES/Footer";

export function Signup() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([])

    async function onLoad() {
        setLoading(true);
        await firebase_GetAllDocuments(db, 'Categories', (categories) => {
            setCategories(categories);
            setLoading(false)
        })
    }

    async function onSignUp() {
        const businessName = document.querySelector('#tbBusinessName').value;
        const email = document.querySelector('#tbEmail').value;
        const phone = document.querySelector('#tbPhone').value;
        const contactName = document.querySelector('#tbContactName').value;
        const category = document.querySelector('#ddCategory').value;
        const desc = document.querySelector('#tbDesc').value;
        const password = document.querySelector('#tbPassword').value;
        const passwordConf = document.querySelector('#tbPasswordConfirmation').value;

        if (businessName == "" || email == "" || phone == "" || contactName == "" || category == "" || desc == "" || password == "" || passwordConf == "") {
            alert("Please fill out all fields in this form.")
            return;
        }

        if (password.length < 8) {
            alert("Password must be at least 8 characters.")
            return;
        }

        if (password !== passwordConf) {
            alert("Please make sure passwords match.")
            return;
        }

        setLoading(true)

        const args = {
            name: businessName,
            email: email,
            phone: phone,
            contactName: contactName,
            category: category,
            description: desc.replaceAll('\n', 'jjj'),
            views: 0
        }

        await auth_CreateUser(auth, email, password, async (user) => {
            if (user !== null) {
                await firebase_CreateDocument(db, 'Businesses', user.uid, args, (success) => {
                    if (success) {
                        // FINISH HERE
                        console.log("SUCCESSFUL SIGNUP")
                        setLoading(false)
                        navigate('/campaigns')
                    } else {
                        alert("There was a problem with your sign up. Please try again later or with a different email.")
                        setLoading(false)
                    }
                })
            } else {
                alert("There was a problem with your sign up. Please try again later or with a different email.")
                setLoading(false)
            }
        })

    }

    useEffect(() => {
        onLoad()
    }, [])

    return <div className="poppins">
        {loading && <Loading />}
        <Navigation />
        <div className="main gap p" style={{ maxWidth: 500 }}>
            <h1 className="text-xxl">Sign Up</h1>
            <p>Join our community of businesses driven to achieve more and grow together.</p>
            <br />
            <div className="border-b-1-black">

                <input id="tbBusinessName" type="text" className="no-border no-outline p-v full-width" placeholder="Business Name" />

            </div>
            <br />
            <div className="border-b-1-black">

                <input id="tbEmail" type="email" className="no-border no-outline p-v full-width" placeholder="Email" />

            </div>
            <br />
            <div className="border-b-1-black">

                <input id="tbPhone" type="text" className="no-border no-outline p-v full-width" placeholder="Phone" />

            </div>
            <br />
            <div className="border-b-1-black">

                <input id="tbContactName" type="text" className="no-border no-outline p-v full-width" placeholder="Contact Name" />

            </div>
            <br />
            <div className="border-b-1-black">
                <select id="ddCategory" className="p-v full-width no-border no-outline">
                    <option value="">Select a category..</option>
                    {
                        sortArrayByObj(categories, 'category').map((cat, i) => {
                            return <option key={i} value={cat.category}>
                                {cat.category}
                            </option>
                        })
                    }
                </select>
            </div>
            <br />
            <div className="border-b-1-black">

                <textarea maxLength={300} id="tbDesc" className="no-border no-outline p-v poppins full-width" placeholder="Talk a bit about your business. 300 characters max."></textarea>

            </div>
            <br />
            <div className="border-b-1-black">

                <input id="tbPassword" type="password" className="no-border no-outline p-v full-width" placeholder="Minimum 8 characters.." />

            </div>
            <br />
            <div className="border-b-1-black">

                <input id="tbPasswordConfirmation" type="password" className="no-border no-outline p-v full-width" placeholder="Type password again.." />

            </div>
            <br />
            <div className="separate-h">
                <div></div>
                <Clickable onPressed={() => {
                    onSignUp()
                }}>
                    <div className="bg-red p-sm full-radius">
                        <p className="white m-h">sign up</p>
                    </div>
                </Clickable>
            </div>

        </div>
        <Footer />
    </div>
}