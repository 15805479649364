import { auth_CheckUser, firebase_CreateDocument, firebase_GetAllDocumentsQueried, firebase_GetDocument } from "react-library/src/firebase"
import { auth, db, db2 } from "../firebase"
import { useNavigate } from "react-router"
import { useEffect, useState } from "react";
import { AsyncImage } from "../COMPONENTS/AsyncImage";
import { DashNavigation } from "../UTILITIES/DashNavigation";
import { Footer } from "../UTILITIES/Footer";

export function Campaigns() {
    const [me, setMe] = useState(null)
    const [campaigns, setCampaigns] = useState([])

    const navigate = useNavigate();

    async function onLoad() {
        await auth_CheckUser(auth, (user) => {

            if (user == null) {
                navigate('/login')
            } else {
                firebase_GetDocument(db, 'Businesses', user.uid, (doc) => {
                    setMe(doc)
                })
                // LOGGED IN
                firebase_GetAllDocumentsQueried(db, 'Campaigns', [
                    { field: 'userId', operator: '==', value: user.uid },
                ], (docs) => {
                    setCampaigns(docs)
                })
            }
        })
    }

    useEffect(() => {
        onLoad()
    }, [])

    return <div className="poppins box">
        <DashNavigation />
        <div className="main box">
            <div className="bg-black">
                <h1 className="white normal">Campaigns</h1>
            </div>
            {/*  */}
            <div className="row box p gap">
                {
                    campaigns.map((camp, i) => {
                        return <div className="p shadow-light box relative" key={i}>
                            <div className="space-around flex-row box">
                                <AsyncImage imagePath={camp.imagePath} maxWidth={350} height={'auto'} />
                            </div>
                            <p className="bg-black absolute top right white p radius-bl">{camp.isCoupon ? 'Coupon' : 'Ad'}</p>
                        </div>
                    })
                }
            </div>
        </div>

        <Footer />
    </div>
}