
import '../STYLES/Loading.css'
export function Loading() {
    return <div className="loading flex-column separate-v">
        <div></div>
        <div className="horizontal-center">
            <p className='poppins'>one moment please...</p>
        </div>
        <div></div>
    </div>
}