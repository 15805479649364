import { useEffect, useState } from "react";
import { DashNavigation } from "../UTILITIES/DashNavigation";
import { auth_CheckUser, firebase_GetAllDocumentsQueriedListener } from "react-library/src/firebase";
import { db, auth } from "../firebase";
import { useNavigate } from "react-router";
import { formatLongerDate } from 'react-library/src/FUNCTIONS/date.js'
import { sortArrayByObj } from "react-library/src/FUNCTIONS/arrays";
import { Footer } from "../UTILITIES/Footer";

export function Notifications() {
    const [notifications, setNotifications] = useState([])
    const navigate = useNavigate()
    async function onLoad() {
        // 
        await auth_CheckUser(auth, async (user) => {
            if (user !== null) {
                await firebase_GetAllDocumentsQueriedListener(db, 'Notifications', [
                    {
                        field: 'userId', operator: '==', value: user.uid
                    }
                ], (docs) => {
                    setNotifications(docs)
                })

            } else {
                navigate('/login')
            }
        })

    }

    useEffect(() => {
        onLoad()
    }, [])

    return <div className="poppins">
        <DashNavigation />
        <div className="main scroll-y">
            <div className="bg-black full-width">
                <p className="white text-md text-normal">Notifications</p>
            </div>
            <br />
            <div className="p">
                {
                    sortArrayByObj(notifications, 'date', true).map((notif, i) => {
                        return <div key={i} className="border-b-1-black p">
                            <p className="text-md red">{notif.title}</p>
                            <p className="text-xs">{notif.body}</p>
                            <p>{formatLongerDate(new Date(notif.date))}</p>
                        </div>
                    })
                }
            </div>
        </div>
        <Footer />
    </div>
}